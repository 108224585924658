<template>
	<div>
		<div class="h5-body" v-if="isMobile">
			<div class="h5-title">时空壶同传会议</div>
			<img class="h5-png" src="@/assets/mobile_enter_img_pc.png" />
			<div class="h5-content">
				暂不支持手机版本，请到电脑端打开链接下载应用入会
			</div>
		</div>
		<div class="meeting-border" v-if="!isMobile">
			<div class="meeting-content">
				<div class="title">
					<img class="title-png" src="@/assets/web_enter_img_logo.png" />
				</div>
				<div class="content-border">
					<div class="pre-title">
						<span style="color: #0a85ff">{{ listInfo.sponsorName }}</span>
						预定的会议
					</div>
					<div class="pre-id">
						会议ID：{{ listInfo.code }}
						<img
							class="copy-icon"
							src="@/assets/web_enter_icon_paste.png"
							@click="handleCopy"
						/>
					</div>
					<div class="deline"></div>
				</div>
				<div class="dr-time">
					<div class="ev-time">
						{{
							listInfo.reserveStart && listInfo.reserveStart.substring(10, 16)
						}}
					</div>
					<div
						class="dr-status"
						v-if="listInfo.status === 'MEETING_STATE_CANCELLED'"
					>
						已取消
					</div>
					<div
						class="dr-status"
						v-if="listInfo.status === 'MEETING_STATE_INIT'"
					>
						待开始
					</div>
					<div
						class="dr-border"
						v-if="
							listInfo.status === 'MEETING_STATE_INIT' ||
							listInfo.status === 'MEETING_STATE_CANCELLED' ||
							listInfo.status === 'MEETING_STATE_NULL'
						"
					></div>
					<div
						class="dr-status1"
						v-if="listInfo.status === 'MEETING_STATE_STARTED'"
					>
						正在进行
					</div>
					<div
						class="dr-border1"
						v-if="listInfo.status === 'MEETING_STATE_STARTED'"
					></div>
					<div
						v-if="listInfo.status === 'MEETING_STATE_ENDED'"
						class="dr-status1"
						style="background: #ff9a66"
					>
						已结束
					</div>
					<div
						v-if="listInfo.status === 'MEETING_STATE_ENDED'"
						class="dr-border1"
						style="background: #ff9a66"
					></div>
					<div class="ev-time">
						{{ listInfo.reserveEnd && listInfo.reserveEnd.substring(10, 16) }}
					</div>
				</div>
				<div class="dr-time">
					<div class="ev-date">
						{{
							listInfo.reserveStart && listInfo.reserveStart.substring(0, 10)
						}}
					</div>
					<div class="dr-area">（{{ listInfo.timeZone }}）</div>
					<div class="ev-date">
						{{ listInfo.reserveEnd && listInfo.reserveEnd.substring(0, 10) }}
					</div>
				</div>
				<div
					class="enter-button"
					v-if="
						listInfo.status === 'MEETING_STATE_INIT' ||
						listInfo.status === 'MEETING_STATE_NULL'
					"
					@click="toMeeting"
				>
					加入会议
				</div>
				<div
					v-if="listInfo.status === 'MEETING_STATE_STARTED'"
					class="enter-button1"
					@click="toMeeting"
				>
					加入会议
				</div>
				<div
					v-if="listInfo.status === 'MEETING_STATE_ENDED'"
					class="enter-button1"
					style="background: #bfbfbf; cursor: not-allowed"
				>
					会议已结束
				</div>
				<div
					v-if="listInfo.status === 'MEETING_STATE_CANCELLED'"
					class="enter-button1"
					style="background: #bfbfbf"
				>
					会议已取消
				</div>
			</div>
		</div>
	</div>
</template>
  
  <script>
import { openUriWithTimeoutHack } from "./protocolcheck.js";
export default {
	name: "meeting",
	data() {
		return {
			isabled: "",
			bgcolor: "",
			isMobile: false,
			listInfo: {},
		};
	},
	created() {
		console.log("====", this.$route);
		this.isMobile = this._isMobile();
		this.getData();
	},

	mounted() {},
	methods: {
		getData() {
			this.listInfo.status = "MEETING_STATE_STARTED";
			// let url =
			// 	"http://47.245.55.239:30013/lvtrans/meeting/meetingInfo/getByInvitationCode";
			let url =
				"http://47.245.55.239:30013/lvtrans/meeting/meetingInfo/getByInvitationCode";

			this.$axios
				.get(url, {
					params: {
						invitationCode: this.$route.params.id,
					},
				})
				.then((res) => {
					if (res.data.code === 200) {
						console.log(res.data);
						res.data.data.reserveStart = this.timeStamp2String(
							res.data.data.reserveStart
						);
						res.data.data.reserveEnd = this.timeStamp2String(
							res.data.data.reserveEnd
						);
						// res.data.data.status = "MEETING_STATE_STARTED";

						this.listInfo = res.data.data;
					} else if (res.data.reasonCode === 2148) {
						this.$message({
							showClose: true,
							message: "找不到会议信息!",
							type: "warning",
						});
					}
					//返回的数据
				})

				.catch((err) => {
					console.log(err); //错误信息
				});
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
		handleCopy() {
			let copyValue = this.listInfo.code;
			let copyInput = document.createElement("input"); //创建input元素
			document.body.appendChild(copyInput); //向页面底部追加输入框
			copyInput.setAttribute("value", copyValue); //添加属性，将url赋值给input元素的value属性
			copyInput.select(); //选择input元素
			document.execCommand("Copy"); //执行复制命令
			this.$message({
				showClose: true,
				message: "复制成功！",
				type: "success",
			});
			//复制之后再删除元素，否则无法成功赋值
			copyInput.remove(); //删除动态创建的节点
		},
		timeStamp2String(time) {
			return this.$moment(new Date(time)).format("YYYY.MM.DD HH:mm");
		},
		toMeeting() {
			openUriWithTimeoutHack(
				// "wemeet://",
				"LiveTranslator://ltconference?id=" + this.$route.params.id,
				// "weixin://",
				// "weixin666://",
				() => {
					console.log("未安装");
					this.$confirm("未安装，下载时空壶同传", "提示", {
						confirmButtonText: "下载",
						cancelButtonText: "取消",
						type: "warning",
					})
						.then(() => {
							window.open("https://cn.timekettle.co/livetranslator.html");
						})
						.catch(() => {
							console.log("取消下载");
						});
				},
				() => {
					console.log("已安装，自动唤起");
				}
			);
		},
	},
};
</script>
  
 <style scoped>
	.meeting-border {
		min-height: 100vh;
		width: 100%;
		background-image: url("~@/assets/web_enter_bg01.png");
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.meeting-content {
		width: 320px;
		height: 387px;
		background: #ffffff;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
		border-radius: 9px;
	}
	.title {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 63px;
		background: #f6f6f7;
	}
	.title-png {
		width: 90px;
		height: 21px;
	}
	.deline {
		width: 277px;
		height: 1px;
		background: #d9d9d9;
		margin: auto;
		margin-bottom: 32px;
		margin-top: 32px;
	}
	.content-border {
		text-align: center;
	}
	.pre-title {
		height: 27px;
		font-size: 19px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 27px;
		margin-bottom: 8px;
		margin-top: 32px;
	}
	.pre-id {
		height: 17px;
		font-size: 13px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 17px;
	}
	.copy-icon {
		width: 14px;
		height: 14px;
		cursor: pointer;
	}
	.dr-time {
		display: flex;
		justify-content: center;
	}
	.dr-border {
		width: 117px;
		height: 1px;
		background: #d9d9d9;
		margin: 18px 5px 17px 5px;
	}
	.dr-border1 {
		width: 117px;
		height: 1px;
		background: #a4dc9a;
		margin: 18px 5px 17px 5px;
	}
	.ev-time {
		width: 64px;
		height: 36px;
		font-size: 26px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 36px;
	}
	.ev-date {
		width: 80px;
		height: 24px;
		font-size: 14px;

		transform: scale(0.5);
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 12px;
	}
	.dr-area {
		text-align: center;
		width: 114px;
		height: 24px;
		font-size: 14px;

		transform: scale(0.5);
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8c8c8c;
		line-height: 12px;
	}
	.enter-button {
		cursor: pointer;
		width: 256px;
		height: 32px;
		background: #ffffff;
		border-radius: 16px;
		border: 1px solid #8c8c8c;
		margin: auto;
		text-align: center;
		line-height: 34px;
		margin-top: 64px;
		font-size: 11px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 34px;
	}
	.enter-button1 {
		cursor: pointer;
		width: 256px;
		height: 32px;
		background: #0a85ff;
		border-radius: 16px;
		margin: auto;
		text-align: center;
		margin-top: 64px;
		font-size: 11px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 32px;
	}
	.dr-status {
		position: absolute;
		width: 84px;
		height: 36px;
		background: #bfbfbf;
		border-radius: 18px;
		font-size: 18px;
		transform: scale(0.5);
		text-align: center;

		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 36px;
	}
	.dr-status1 {
		position: absolute;
		width: 100px;
		height: 36px;
		background: #49b625;

		border-radius: 18px;
		font-size: 18px;
		transform: scale(0.5);
		text-align: center;

		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 36px;
	}
	.h5-body {
		height: 100vh;
		background: #f6f6f7;
		text-align: center;
	}
	.h5-title {
		height: 100px;
		background: #ffffff;
		box-shadow: 0px 1px 0px 0px rgba(191, 191, 191, 1);
		font-size: 34px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 100px;
		text-align: center;
	}
	.h5-png {
		width: 72%;
		height: 18vh;
		margin-top: 20%;
	}
	.h5-content {
		margin: auto;
		width: 90%;
		height: 84px;
		font-size: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 42px;
	}
</style>

  
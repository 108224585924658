var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isMobile)?_c('div',{staticClass:"h5-body"},[_c('div',{staticClass:"h5-title"},[_vm._v("时空壶同传会议")]),_c('img',{staticClass:"h5-png",attrs:{"src":require("@/assets/mobile_enter_img_pc.png")}}),_c('div',{staticClass:"h5-content"},[_vm._v(" 暂不支持手机版本，请到电脑端打开链接下载应用入会 ")])]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"meeting-border"},[_c('div',{staticClass:"meeting-content"},[_vm._m(0),_c('div',{staticClass:"content-border"},[_c('div',{staticClass:"pre-title"},[_c('span',{staticStyle:{"color":"#0a85ff"}},[_vm._v(_vm._s(_vm.listInfo.sponsorName))]),_vm._v(" 预定的会议 ")]),_c('div',{staticClass:"pre-id"},[_vm._v(" 会议ID："+_vm._s(_vm.listInfo.code)+" "),_c('img',{staticClass:"copy-icon",attrs:{"src":require("@/assets/web_enter_icon_paste.png")},on:{"click":_vm.handleCopy}})]),_c('div',{staticClass:"deline"})]),_c('div',{staticClass:"dr-time"},[_c('div',{staticClass:"ev-time"},[_vm._v(" "+_vm._s(_vm.listInfo.reserveStart && _vm.listInfo.reserveStart.substring(10, 16))+" ")]),(_vm.listInfo.status === 'MEETING_STATE_CANCELLED')?_c('div',{staticClass:"dr-status"},[_vm._v(" 已取消 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_INIT')?_c('div',{staticClass:"dr-status"},[_vm._v(" 待开始 ")]):_vm._e(),(
						_vm.listInfo.status === 'MEETING_STATE_INIT' ||
						_vm.listInfo.status === 'MEETING_STATE_CANCELLED' ||
						_vm.listInfo.status === 'MEETING_STATE_NULL'
					)?_c('div',{staticClass:"dr-border"}):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_STARTED')?_c('div',{staticClass:"dr-status1"},[_vm._v(" 正在进行 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_STARTED')?_c('div',{staticClass:"dr-border1"}):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_ENDED')?_c('div',{staticClass:"dr-status1",staticStyle:{"background":"#ff9a66"}},[_vm._v(" 已结束 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_ENDED')?_c('div',{staticClass:"dr-border1",staticStyle:{"background":"#ff9a66"}}):_vm._e(),_c('div',{staticClass:"ev-time"},[_vm._v(" "+_vm._s(_vm.listInfo.reserveEnd && _vm.listInfo.reserveEnd.substring(10, 16))+" ")])]),_c('div',{staticClass:"dr-time"},[_c('div',{staticClass:"ev-date"},[_vm._v(" "+_vm._s(_vm.listInfo.reserveStart && _vm.listInfo.reserveStart.substring(0, 10))+" ")]),_c('div',{staticClass:"dr-area"},[_vm._v("（"+_vm._s(_vm.listInfo.timeZone)+"）")]),_c('div',{staticClass:"ev-date"},[_vm._v(" "+_vm._s(_vm.listInfo.reserveEnd && _vm.listInfo.reserveEnd.substring(0, 10))+" ")])]),(
					_vm.listInfo.status === 'MEETING_STATE_INIT' ||
					_vm.listInfo.status === 'MEETING_STATE_NULL'
				)?_c('div',{staticClass:"enter-button",on:{"click":_vm.toMeeting}},[_vm._v(" 加入会议 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_STARTED')?_c('div',{staticClass:"enter-button1",on:{"click":_vm.toMeeting}},[_vm._v(" 加入会议 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_ENDED')?_c('div',{staticClass:"enter-button1",staticStyle:{"background":"#bfbfbf","cursor":"not-allowed"}},[_vm._v(" 会议已结束 ")]):_vm._e(),(_vm.listInfo.status === 'MEETING_STATE_CANCELLED')?_c('div',{staticClass:"enter-button1",staticStyle:{"background":"#bfbfbf"}},[_vm._v(" 会议已取消 ")]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{staticClass:"title-png",attrs:{"src":require("@/assets/web_enter_img_logo.png")}})])
}]

export { render, staticRenderFns }